import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {title} from "@core/utils/filter";

export default function userUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#'},
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'user_id', label: 'ID', sortable: true },
    //{ key: 'company_id', label: '公司' },
    // { key: 'company', label: '公司' },
    { key: 'deptartment_id', label: '部门/职务', sortable: true },
    //{ key: 'user_id', label: '用户', sortable: true },
    { key: 'username', label: '登录名', sortable: true },
    //{ key: 'password', label: '密码', sortable: true },
    { key: 'full_name', label: '用户', sortable: true },
    //{ key: 'avatar', label: '头像', sortable: true },
   /* { key: 'mobile', label: '手机号', sortable: true },*/
    { key: 'email', label: '邮件/手机号', sortable: true },
    //{ key: 'job_num', label: '工号', sortable: true },
    //{ key: 'role_id', label: '角色', sortable: true },
    { key: 'role', label: '角色' },
    { key: 'warehouse', label: '所属仓库' },
    { key: 'store', label: '所属团队' },
    { key: 'purchase_team', label: '所属销售权团队' },
    { key: 'default_purchase_team_id', label: '默认销售权团队'},
    //{ key: 'level', label: '职位  user_level', sortable: true },
    //{ key: 'login_time', label: '登录时间', sortable: true },
    //{ key: 'login_num', label: '登录次数', sortable: true },
    //{ key: 'ip_address', label: 'IP', sortable: true },
    //{ key: 'status', label: '状态', sortable: true },
    //{ key: 'remark', label: '', sortable: true },
    { key: 'state', label: '启用', sortable: true },
    //{ key: 'create_time', label: '添加时间', sortable: true },
    //{ key: 'creator', label: '添加人', sortable: true },
    //{ key: 'update_time', label: '更新时间', sortable: true },
    //{ key: 'updator', label: '更新人', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('user_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('user/searchUserList', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        mobile:condition.value.mobile,
        store:condition.value.store,
        warehouse:condition.value.warehouse,
        username:condition.value.username,
        fullName:condition.value.fullName,
        level:condition.value.userLevel==null?null:condition.value.userLevel.value,
        roleId:condition.value.roleId==null?null:condition.value.roleId.value,
        deptartmentId:condition.value.departmentId==null?null:condition.value.departmentId.value,
        purchaseTeam:condition.value.purchaseTeam==null?null:condition.value.purchaseTeam.value
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '用户列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
