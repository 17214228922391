<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="登录名:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.username"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="用户:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.fullName"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="手机号码:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.mobile"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="所属团队:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.store"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="所属仓库:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.warehouse"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="所属销售权团队:"
                    label-for="user_level"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.purchaseTeam"
                      name="user_level"
                      class="select-size-sm"
                      placeholder="请选择所属销售权团队"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="职务:"
                    label-for="user_level"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('user_level')"
                      v-model="condition.userLevel"
                      name="user_level"
                      class="select-size-sm"
                      placeholder="请选择职务"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="角色"
                    label-for="role_id"
                    label-size="sm"
                >
                  <v-select
                      id="role_id"
                      :options="getCodeOptions('role')"
                      v-model="condition.roleId"
                      name="role_id"
                      class="select-size-sm"
                      placeholder="请选择角色"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="部门"
                    label-for="deptartment_id"
                    label-size="sm"
                >
                  <v-select
                      id="deptartment_id"
                      :options="getCodeOptions('department')"
                      v-model="condition.departmentId"
                      name="deptartment_id"
                      class="select-size-sm"
                      placeholder="请选择部门"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="reload"
                >
                  <span class="align-right">清空勾选</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >
        <template v-slot:head(#)="data">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj.sync="isCheckedObj"
              :table="table"
              type="head"
              :row="data"
          />
        </template>
        <template v-slot:cell(#)="row">
          <xy-checkbox
              :checked="checked"
              :allChecked.sync="allChecked"
              :indeterminate="indeterminate"
              :isCheckedObj="isCheckedObj"
              :table="table"
              type="row"
              :row="row"
              :rowChecked.sync="isCheckedObj['index'+row.item.id]"
          />
        </template>

        <!-- Columns -->
        <template #cell(user_id)="data">
<!--          <b-link-->
<!--              :to="{ name: 'apps-user-edit', query: { id: data.item.id } }"-->
<!--              class="font-weight-bold d-block text-nowrap"-->
<!--          >-->
            #{{ data.item.user_id }}
<!--          </b-link>-->
        </template>


        <template #cell(default_purchase_team_id)="data">
          {{getCodeLabel('purchase_team', data.item.default_purchase_team_id)}}
        </template>


        <template #cell(company)="data">
          {{getCodeLabel('company', data.item.company_id)}}
          <small class="text-muted" v-if="data.item.department_id">{{getCodeLabel('department', data.item.department_id)}}</small>
        </template>

        <!--部门+职务-->
        <template #cell(deptartment_id)="data">
          <b-media vertical-align="center">
            <small class="font-weight-bold d-block text-nowrap"
                   v-if="data.item.deptartment_id"
            >
              {{ getCodeLabel("department", data.item.deptartment_id)}}
            </small>
            <small class="font-weight-bold d-block text-nowrap"
                   v-if="data.item.level"
            >
              {{getCodeLabel('user_level', data.item.level)}}
            </small>
          </b-media>
        </template>

        <template #cell(full_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="get_upload_url(data.item.avatar)"
                  :text="avatarText(data.item.full_name)"
                  :variant="`light-${getCodeColor('role', data.item.role_id)}`"
                  :to="{ name: 'apps-user-view', query: { id: data.item.id } }"
              />
            </template>
            <b-link
                :to="{ name: 'apps-user-view', query: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.full_name }}
            </b-link>
            <small class="text-muted" v-if="data.item.job_num">@{{ data.item.job_num }}</small>
          </b-media>
        </template>
        <!--tel + email-->
        <template #cell(email)="data">
          <b-media vertical-align="center">
            <small class="font-weight-bold d-block text-nowrap"
                   v-if="data.item.email"
            >
              <feather-icon
                      icon="MailIcon"
                      size="12"
                      class="mr-50"

              />
              {{ data.item.email }}
            </small>
            <small class="font-weight-bold d-block text-nowrap"
                   v-if="data.item.mobile"
            >
              <feather-icon
                      icon="PhoneIcon"
                      size="12"
                      class="mr-50"
              />
                   {{ data.item.mobile }}
            </small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
                :icon="getCodeIcon('role', data.item.role_id)"
                size="18"
                class="mr-50"
                :class="`text-${getCodeColor('role', data.item.role_id)}`"
            />
            <span class="align-text-top text-capitalize">{{getCodeLabel('role', data.item.role_id)}}</span>
          </div>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{getCodeLabel('state', data.item.state)}}
            </b-badge>
          </b-link>
        </template>

        <template #cell(warehouse)="data">
          <div>
            {{  data.item.ext.warehouse.substring(0, 10) }}
            <feather-icon
                v-if="data.item.ext.warehouse.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.ext.warehouse+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <template #cell(store)="data">
          <div>
            {{  data.item.ext.store.substring(0, 10) }}
            <feather-icon
                v-if="data.item.ext.store.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.ext.store+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <template #cell(purchase_team)="data">
          <div>
            {{  data.item.ext.purchaseTeam.substring(0, 10) }}
            <feather-icon
                v-if="data.item.ext.purchaseTeam.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.ext.purchaseTeam+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-user-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-user-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="resetpass(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">重置密码</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, getCodeIcon, get_upload_path, get_upload_url } from '@core/utils/filter'
import userUseList from './userUseList'
import userStore from './userStore'
import {useToast} from "vue-toastification/composition";
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import XyCheckbox from "@/views/components/xy/XyCheckbox";

export default {
  components: {
    XyCheckbox,
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('user/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
  },
  data(){
    return{
      checked: [],
      allChecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      advanced_search_modal: false,
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('user')) store.registerModule('user', userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('user')) store.unregisterModule('user')
    })

    const resetpass = function (id) {
      store.dispatch('user/resetpass', {id: id}).then(res => {
        if (res.data.code == 0) {
          toast.success("操作成功")
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked = []
      this.indeterminate = false
      this.allchecked = false
      refetchData()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = userUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // Filter
      avatarText,
      resetpass,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      getCodeIcon,
      get_upload_path,
      get_upload_url,
      advanced_search,
      fromChildren,
      // UI
      reload,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
